import CustomEdge from 'src/scenes/AddEditRiver/components/MergeSplit/CustomEdge';
import { EdgeTypes, NodeTypes, MarkerType, Edge, Node } from 'reactflow';
import CustomNode from 'src/scenes/AddEditRiver/components/MergeSplit/CustomNode';
import dagre from 'dagre';
import { GetMergeFlowConfigurationHistoricalOutput } from 'src/domain/service/RiverSystemService/dto';
import CustomDisplayOnlyEdge from './CustomDisplayOnlyEdge';

export const edgeTypes: EdgeTypes = {
  custom: CustomEdge,
};

export const edgeDisplayOnlyTypes: EdgeTypes = {
  custom: CustomDisplayOnlyEdge,
};

export const nodeTypes: NodeTypes = {
  custom: CustomNode,
};

export interface StationNode {
  id: string;
  name: string;
  positionX?: number;
  positionY?: number;
  isEnabled: boolean;
}

export interface MergeSplitConfiguration {
  flows: MergeSplitTemplate;
  stations: StationNode[];
}

type MergeSplitTemplate = Array<{
  id: number;
  stationSourceName: string;
  stationTargetName: string;
  sourceId: number;
  targetId: number;
  percentage: number;
}>;

export const defaultEdgeParams = {
  data: {
    // inflow/outflow default case
    label: '100',
  },
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 15,
    height: 15,
    color: '#000000',
  },
  type: 'custom',
};

export const generateNodes = (stations: StationNode[]): Node[] => {
  return stations.map((s) => {
    return {
      id: s.id,
      position: { x: s.positionX ?? 0, y: s.positionY ?? 0 },
      data: { label: s.name },
      type: 'custom',
    };
  });
};

export const generateEdges = (flows: MergeSplitTemplate): Edge[] => {
  const edges: Edge[] = [];
  for (let i = 0; i < flows.length; i += 1) {
    const flow = flows[i];
    edges.push({
      ...defaultEdgeParams,
      data: { label: flow.percentage },
      id: flow.id.toString(),
      source: flow.sourceId.toString(),
      target: flow.targetId.toString(),
    });
  }
  return edges;
};

export const generateEdgesForEmptyConfiguration = (stations: StationNode[]): Edge[] => {
  const edges: Edge[] = [];
  for (let i = 0; i < stations.length - 1; i += 1) {
    const stationOne = stations[i];
    const stationTwo = stations[i + 1];
    edges.push({
      ...defaultEdgeParams,
      id: `reactflow__edge_${stationOne.id}_${stationTwo.id}`,
      source: stationOne.id,
      target: stationTwo.id,
    });
  }
  return edges;
};

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 300;
const nodeHeight = 150;

export const getLayoutedElements = (
  nodes: Node[],
  edges: Edge[],
): { nodes: Node[]; edges: Edge[] } => {
  dagreGraph.setGraph({ rankdir: 'LR' });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  const newNodes = nodes.map((node) => {
    if (node.position.x && node.position.y) {
      return {
        ...node,
        position: { x: node.position.x - nodeWidth / 4, y: node.position.y - nodeHeight / 4 },
      };
    }
    const nodeWithPosition = dagreGraph.node(node.id);
    return {
      ...node,
      position: {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      },
    };
  });

  return { nodes: newNodes, edges };
};

export const getMergeFlowConfiguration = (
  historical: GetMergeFlowConfigurationHistoricalOutput,
): MergeSplitConfiguration => {
  return {
    flows: historical?.flows,
    stations: historical?.stations?.map((s) => ({
      id: s.id.toString(),
      isEnabled: true,
      name: s.name,
    })),
  };
};
