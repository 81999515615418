import './styles/index.less';

import React from 'react';
import ReactDOM from 'react-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { BrowserRouter } from 'react-router-dom';
import { msalConfig } from 'src/utils/consts';
import AbpSessionLoader from 'src/AbpSessionLoader';
import App from 'src/App';
import Router from 'src/components/Router';
import RootStore from 'src/stores/rootStore';
import { StoreContext } from 'src/stores/storesConfig';
import registerServiceWorker from './registerServiceWorker';
import 'reactflow/dist/style.css';

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <StoreContext.Provider value={new RootStore()}>
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <AbpSessionLoader>
          <App>
            <Router />
          </App>
        </AbpSessionLoader>
      </BrowserRouter>
    </MsalProvider>
  </StoreContext.Provider>,
  document.getElementById('root') as HTMLElement,
);

registerServiceWorker();
