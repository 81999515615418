import moment, { Moment } from 'moment';

export const getDateAndTimeWithoutUTC = (date: Moment, time: Moment): string => {
  const dateInputValue = date.format('YYYY-MM-DD');
  const timeInputValue = time.format('h:mm A');
  const dateTime = `${dateInputValue} ${timeInputValue}`;
  return dateTime;
};

export const getUTCDateAndTime = (date: Moment, time: Moment): string => {
  const dateInputValue = date.format('YYYY-MM-DD');
  const timeInputValue = time.format('HH:mm');
  const dateTime = moment(`${dateInputValue} ${timeInputValue}`);
  return moment.utc(dateTime).format();
};

export const getDateFromUrl = (dateRange?: string): Array<string> => {
  return dateRange ? dateRange.split(',') : [];
};

export const convertDateToUrl = (dateRange?: [Moment, Moment]): string | undefined => {
  const [startDate, endDate] = dateRange ?? [];
  return dateRange
    ? `${startDate?.format('YYYY-MM-DD')},${endDate?.format('YYYY-MM-DD')}`
    : undefined;
};

export const getRangePickerValue = (
  startDate: string,
  endDate: string,
): [Moment, Moment] | undefined => {
  return startDate && endDate ? [moment(startDate), moment(endDate)] : undefined;
};
