import { makeAutoObservable } from 'mobx';
import { RegionsByCountry } from 'src/domain/entity/RegionsByCountry';
import { River } from 'src/domain/entity/River';
import { RiverSystemService } from 'src/domain/service/RiverSystemService';
import ScenarioService from 'src/domain/service/ScenarioService';

export enum CurrentStep {
  DATA_LOADING,
  DATA_READY,
  RIVER_SELECTED,
  CREATE_SCENARIO,
  SUBMITTING_SCENARIO,
  SCENARIO_CREATED,
  LOADING_TEMPLATE,
  CREATE_TEMPLATE,
  SUBMITTING_TEMPLATE,
  TEMPLATE_DATABRICKS_FAIL,
  TEMPLATE_CREATED,
  TEMPLATE_RESET_OR_DUPLICATE,
  VALIDATION_MODEL_LOADING,
  VALIDATION_MODEL,
  INITIATING_CANCEL_SANITY_CHECK,
  CANCELLING_SANITY_CHECK,
  VALIDATION_MODEL_SUBMITTING,
  VALIDATION_MODEL_FETCHING_RESULTS,
  VALIDATION_MODEL_DATABRICKS_FAIL,
  VALIDATION_MODEL_FAIL,
  VALIDATION_MODEL_SUCCESS,
  RUN_MODEL,
  RUN_MODEL_FINISHED,
}

export default class CreateScenarioStore {
  currentStep = CurrentStep.DATA_LOADING;

  countriesName: Array<string> = [];

  regionsByCountry: RegionsByCountry = {};

  regionsName: Array<string> = [];

  riverSystems: Array<River> = [];

  constructor(
    readonly riverSystemService: RiverSystemService,
    readonly scenarioService: ScenarioService,
  ) {
    makeAutoObservable(this);
  }

  async fetchCountries(): Promise<void> {
    try {
      this.setCurrentStep(CurrentStep.DATA_LOADING);
      const regionsByCountry = await this.riverSystemService.getCountriesGrouppedByRegion();
      this.setCountries(Object.keys(regionsByCountry));
      this.regionsByCountry = regionsByCountry;
    } finally {
      this.setCurrentStep(CurrentStep.DATA_READY);
    }
  }

  async fetchRegions(country: string): Promise<void> {
    this.setRegions(this.regionsByCountry[country]);
  }

  async fetchRiverSystems(country: string, region: string): Promise<void> {
    try {
      this.setCurrentStep(CurrentStep.DATA_LOADING);
      const riverSystems = await this.riverSystemService.getByCountryAndRegion(country, region);
      this.setRiverSystems(riverSystems);
    } finally {
      this.setCurrentStep(CurrentStep.DATA_READY);
    }
  }

  public setCountries(countriesName: Array<string>): void {
    this.countriesName = countriesName;
  }

  public setRegions(regionsName: Array<string>): void {
    this.regionsName = regionsName;
  }

  public setRiverSystems(riverSystems: Array<River>): void {
    this.riverSystems = riverSystems;
  }

  public setCurrentStep(step: CurrentStep): void {
    this.currentStep = step;
  }

  public isLoadingInfo(): boolean {
    return this.currentStep === CurrentStep.DATA_LOADING;
  }

  public isRiverSelected(): boolean {
    return this.currentStep >= CurrentStep.RIVER_SELECTED;
  }

  public isCreatingScenario(): boolean {
    return this.currentStep === CurrentStep.SUBMITTING_SCENARIO;
  }

  public isSubmittingTemplate(): boolean {
    return this.currentStep === CurrentStep.SUBMITTING_TEMPLATE;
  }

  public isScenarioCreated(): boolean {
    return this.currentStep >= CurrentStep.CREATE_SCENARIO;
  }

  public isTemplateLoading(): boolean {
    return this.currentStep === CurrentStep.LOADING_TEMPLATE;
  }

  public isTemplateFileReady(): boolean {
    return this.currentStep === CurrentStep.TEMPLATE_CREATED;
  }

  public isValidationModelLoading(): boolean {
    return this.currentStep === CurrentStep.VALIDATION_MODEL_LOADING;
  }

  public isValidationModelProcessing(): boolean {
    return (
      this.currentStep === CurrentStep.VALIDATION_MODEL_SUBMITTING ||
      this.currentStep === CurrentStep.VALIDATION_MODEL_FETCHING_RESULTS
    );
  }

  public isValidationModelFetchingResults(): boolean {
    return this.currentStep === CurrentStep.VALIDATION_MODEL_FETCHING_RESULTS;
  }

  public isValidationModelSuccess(): boolean {
    return this.currentStep === CurrentStep.VALIDATION_MODEL_SUCCESS;
  }

  public isValidationModelFailed(): boolean {
    return (
      this.currentStep === CurrentStep.VALIDATION_MODEL_FAIL ||
      this.currentStep === CurrentStep.VALIDATION_MODEL_DATABRICKS_FAIL
    );
  }

  public isSanityCheckResultsReady(): boolean {
    return this.currentStep >= CurrentStep.VALIDATION_MODEL_FETCHING_RESULTS;
  }

  public isSanityCheckCompleted(): boolean {
    return this.currentStep === CurrentStep.VALIDATION_MODEL_SUCCESS;
  }

  public isShowCancelSanityCheck(): boolean {
    return (
      this.currentStep === CurrentStep.INITIATING_CANCEL_SANITY_CHECK ||
      this.currentStep === CurrentStep.CANCELLING_SANITY_CHECK ||
      this.currentStep === CurrentStep.VALIDATION_MODEL_SUBMITTING ||
      this.currentStep === CurrentStep.VALIDATION_MODEL_FETCHING_RESULTS
    );
  }

  public isCancellingSanityCheck(): boolean {
    return this.currentStep === CurrentStep.CANCELLING_SANITY_CHECK;
  }

  public isInitiatingCancelSanityCheck(): boolean {
    return this.currentStep === CurrentStep.INITIATING_CANCEL_SANITY_CHECK;
  }

  get hasModelAlreadyRan(): boolean {
    return this.currentStep === CurrentStep.RUN_MODEL;
  }
}
