import BlobService from 'src/domain/service/BlobService';
import HttpAdapter from 'src/infra/http/HttpAdapter';

export default class BlobRestService implements BlobService {
  constructor(readonly blobAdapter: HttpAdapter) {}

  async updateTemplateFile(url: string, file: File): Promise<void> {
    await this.blobAdapter.put(url, file);
  }
}
