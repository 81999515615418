import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Input, InputProps } from 'antd';
import React, { FC, useState } from 'react';
import { EdgeProps, getBezierPath, EdgeLabelRenderer, BaseEdge, useReactFlow } from 'reactflow';

const CustomEdge: FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  markerEnd,
  selected,
}) => {
  const { setEdges } = useReactFlow();
  const [value, setValue] = useState<string>(data.label);
  const [isEditing, setIsEditing] = useState(false);
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const onEnableEdit = () => {
    setIsEditing((prev) => !prev);
  };

  const confirmEditing = () => {
    setEdges((prevEdges) => {
      return prevEdges.map((e) => {
        if (e.id === id) {
          return { ...e, data: { label: value } };
        }
        return e;
      });
    });
    setValue(value);
    setIsEditing((prev) => !prev);
  };

  const onChangeInput: InputProps['onChange'] = (e) => {
    const { value: newValue } = e.target;
    if (!newValue) setValue('0');
    if (!/^\d+$/.test(newValue)) return;
    if (newValue.length > 3) return;
    const parsedValue = parseInt(newValue, 10);
    setValue(Math.min(100, parsedValue).toString());
  };

  const onEnter: InputProps['onKeyDown'] = (e) => {
    if (e.key === 'Enter') confirmEditing();
  };

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        markerEnd={markerEnd}
        style={{
          strokeWidth: 2,
          stroke: selected ? 'red' : '#000000',
        }}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            pointerEvents: 'all',
            borderRadius: 5,
            fontSize: 12,
            fontWeight: 700,
          }}
          className="nodrag nopan"
        >
          {isEditing ? (
            <Input
              value={value}
              style={{ width: 100 }}
              onChange={onChangeInput}
              onKeyDown={onEnter}
              suffix={
                <Button
                  type="text"
                  style={{ color: '#00FF00' }}
                  onClick={confirmEditing}
                  icon={<CheckCircleOutlined />}
                />
              }
            />
          ) : (
            <Button
              type="text"
              style={{
                backgroundColor: '#FFFFFF',
                borderColor: '#C8C9C7',
              }}
              onClick={onEnableEdit}
            >
              {`${value}%`}
            </Button>
          )}
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomEdge;
