import AadAuthentication from 'src/domain/entity/AadAuthentication';
import Authentication from 'src/domain/entity/Authentication';
import AuthenticationResult from 'src/domain/entity/AuthenticationResult';
import TokenAuthService from 'src/domain/service/TokenAuthService';
import HttpAdapter from 'src/infra/http/HttpAdapter';

export default class TokenAuthRestService implements TokenAuthService {
  constructor(readonly httpAdapter: HttpAdapter) {}

  public async authenticate(authenticationInput: Authentication): Promise<AuthenticationResult> {
    const data = await this.httpAdapter.post<{ result: AuthenticationResult }>(
      'api/TokenAuth/Authenticate',
      authenticationInput,
    );
    return data.result;
  }

  public async aadAuthenticate(
    authenticationInput: AadAuthentication,
  ): Promise<AuthenticationResult> {
    const data = await this.httpAdapter.post<{ result: AuthenticationResult }>(
      'api/TokenAuth/ExternalAuthenticate',
      {
        AuthProvider: authenticationInput.providerName,
        providerKey: authenticationInput.providerKey,
        ProviderAccessCode: authenticationInput.accessToken,
      },
      {
        headers: {
          abpTenantId: 'Default',
        },
      },
    );
    return data.result;
  }
}
