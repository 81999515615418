import { QueueInfo } from 'src/domain/entity/QueueInfo';
import ScenarioQueueService from 'src/domain/service/ScenarioQueueService';
import { SwitchPrioritiesInput } from 'src/domain/service/ScenarioQueueService/dto';
import HttpAdapter from 'src/infra/http/HttpAdapter';

export default class ScenarioQueueRestService implements ScenarioQueueService {
  constructor(readonly httpAdapter: HttpAdapter) {}

  async cancelByScenarioId(scenarioId: number): Promise<void> {
    await this.httpAdapter.delete<void>('api/services/app/ScenarioQueue/DeleteByScenarioId', {
      scenarioId,
    });
  }

  async getAll(): Promise<Array<QueueInfo>> {
    const data: any = await this.httpAdapter.get('api/services/app/ScenarioQueue/GetAll');
    return data.result;
  }

  async switchPriorities(input: SwitchPrioritiesInput): Promise<void> {
    await this.httpAdapter.put('api/services/app/ScenarioQueue/SwitchPriorities', input);
  }
}
