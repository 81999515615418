import { Popover } from 'antd';
import './index.less';
import React, { FC } from 'react';
import { Handle, Position, NodeProps } from 'reactflow';
import { InfoCircleOutlined } from '@ant-design/icons';

const CustomNode: FC<NodeProps> = ({ data, isConnectable }) => {
  const nodeText = data.label;
  return (
    <div className="custom-node-container">
      <Handle
        className="custom-handler"
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
      />
      <div>
        {nodeText.length > 18 ? (
          <Popover placement="bottomRight" className="mr-1" content={nodeText}>
            {`${nodeText.slice(0, 18)} ...`}
            <InfoCircleOutlined
              className="ml-1"
              style={{ marginTop: 3, color: 'black', cursor: 'pointer' }}
              type="primary"
            />
          </Popover>
        ) : (
          nodeText
        )}
      </div>
      <Handle
        className="custom-handler"
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
      />
    </div>
  );
};

export default CustomNode;
