/* eslint-disable import/prefer-default-export */
import { EntityChange } from 'src/domain/entity/EntityChange';

interface EntityChangesOutput {
  id: number;
  entityName: string;
  entityValue: string;
  changeType: string;
  propertyChanges: Array<{
    id: number;
    newValue: string;
    originalValue: string;
    propertyName: string;
  }>;
}

export const toEntityChanges = (items: EntityChangesOutput[]): EntityChange[] => {
  const newItems = items.reduce((acum: EntityChange[], next: EntityChangesOutput) => {
    const changes = next.propertyChanges.map((c) => ({
      id: Number(`${next.id}-${c.id}`),
      entityName: next.entityName,
      entityValue: next.entityValue,
      changeType: next.changeType,
      propertyName: c.propertyName,
      originalValue: c.originalValue,
      newValue: c.newValue,
    }));
    return acum.concat(changes);
  }, []);
  return newItems;
};
