import GetCurrentLoginInformations from 'src/domain/entity/GetCurrentLoginInformations';
import SessionService from 'src/domain/service/SessionService';
import HttpAdapter from 'src/infra/http/HttpAdapter';

export default class SessionRestService implements SessionService {
  constructor(readonly httpAdapter: HttpAdapter) {}

  async getCurrentLoginInformations(): Promise<GetCurrentLoginInformations> {
    const data = await this.httpAdapter.get<{ result: GetCurrentLoginInformations }>(
      'api/services/app/Session/GetCurrentLoginInformations',
      {
        headers: {
          'Abp.TenantId': abp.multiTenancy.getTenantIdCookie(),
        },
      },
    );

    return data.result;
  }
}
