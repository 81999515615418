import * as React from 'react';

import { Route, Switch } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import utils from '../../utils/utils';
import { ROUTES } from './router.config';

const Router = () => {
  const appPath = ROUTES.BASE_APP_ROUTE;
  const userPath = ROUTES.BASE_USER_ROUTE;
  const UserLayout = utils.getRoute(userPath).component;
  const AppLayout = utils.getRoute(appPath).component;

  return (
    <Switch>
      <ProtectedRoute path={appPath} render={(props: any) => <AppLayout {...props} />} />
      <Route path={userPath} render={(props: any) => <UserLayout {...props} />} />
    </Switch>
  );
};

export default Router;
