import { makeAutoObservable } from 'mobx';
import { getCurrentStepFromScenarioState } from 'src/domain/entity/ScenarioState';
import { RiverSystemService } from 'src/domain/service/RiverSystemService';
import ScenarioService from 'src/domain/service/ScenarioService';
import RootStore from 'src/stores/rootStore';
import { EntityChange } from 'src/domain/entity/EntityChange';
import { CurrentStep } from './createScenarioStore';

export default class TemplateConfigurationStore {
  riverChanges: EntityChange[] = [];

  changeTime = '';

  isSubmitting = false;

  constructor(
    readonly _: RootStore,
    readonly scenarioService: ScenarioService,
    readonly riverSystemService: RiverSystemService,
  ) {
    makeAutoObservable(this, { _: false });
  }

  public async init(scenarioId: number): Promise<void> {
    if (this._.scenarioDetailsStore.isLoadedScenarioEqual(scenarioId)) {
      const { currentStep } = this._.createScenarioStore;
      const riverSystemId = this._.scenarioDetailsStore.riverSystem.id;
      this._.createScenarioStore.setCurrentStep(CurrentStep.LOADING_TEMPLATE);
      await this.checkIfRiverHasChanged(riverSystemId);
      await this._.riverSystemStore.init(riverSystemId, 'TEMPLATE');
      this._.createScenarioStore.setCurrentStep(currentStep);
      return;
    }

    this._.createScenarioStore.setCurrentStep(CurrentStep.LOADING_TEMPLATE);
    const nextStep = await this._.scenarioDetailsStore.loadScenarioById(scenarioId);
    if (
      nextStep !== CurrentStep.SCENARIO_CREATED &&
      nextStep !== CurrentStep.TEMPLATE_DATABRICKS_FAIL
    ) {
      this._.scenarioDetailsStore.clearScenario();
      throw new Error('This is scenario is not allowed to create a template configuration.');
    }

    const riverSystemId = this._.scenarioDetailsStore.riverSystem.id;
    await this.checkIfRiverHasChanged(riverSystemId);
    await this._.riverSystemStore.init(riverSystemId, 'TEMPLATE');
    this._.createScenarioStore.setCurrentStep(CurrentStep.CREATE_TEMPLATE);
  }

  private async checkIfRiverHasChanged(riverSystemId: number): Promise<void> {
    const res = await this.riverSystemService.checkIfRiverHasChanged(riverSystemId);
    this.setRiverChanges(res.hasRiverChanged ? res.items : []);
    this.setChangeTime(res.changeTime);
  }

  public async markRiverChangeAsRead(riverSystemId: number): Promise<void> {
    try {
      this.setIsSubmitting(true);
      await this.riverSystemService.markRiverChangeAsRead(riverSystemId);
      this.setRiverChanges([]);
    } finally {
      this.setIsSubmitting(false);
    }
  }

  public async submitTemplate(
    scenarioId: number,
    riverSystemName: string,
    startTime: string,
    endTime: string,
  ): Promise<void> {
    try {
      const { stationsStructure, generatorsStructure } = this._.riverSystemStore;
      const { stationFlows } = this._.mergeSplitStore;
      this._.createScenarioStore.setCurrentStep(CurrentStep.SUBMITTING_TEMPLATE);
      const riverSystem = {
        name: riverSystemName,
        stations: stationsStructure
          .filter((s) => s.isEnabled)
          .map((s) => ({
            name: s.name,
            includeStorage: s.includeStorage,
            generators: generatorsStructure[s.name]
              .filter((generator) => generator.isEnabled)
              .map((generator) => ({
                name: generator.name,
                isIncluded: true,
              })),
          })),
      };

      await this.scenarioService.submitSettings({
        id: scenarioId,
        startTime,
        endTime,
        riverSystem,
        riverFlows: stationFlows.map(({ id, ...rest }) => rest),
      });
    } catch (err) {
      this._.createScenarioStore.setCurrentStep(CurrentStep.TEMPLATE_CREATED);
      throw err;
    }
  }

  public async checkSubmitStatus(scenarioId: number): Promise<boolean> {
    const data = await this.scenarioService.get(scenarioId);
    const currentStep = getCurrentStepFromScenarioState(data.state);
    this._.createScenarioStore.setCurrentStep(currentStep);
    return (
      currentStep === CurrentStep.TEMPLATE_CREATED ||
      currentStep === CurrentStep.TEMPLATE_DATABRICKS_FAIL
    );
  }

  public setRiverChanges(riverChanges: EntityChange[]): void {
    this.riverChanges = riverChanges;
  }

  public setChangeTime(changeTime: string): void {
    this.changeTime = changeTime;
  }

  public setIsSubmitting(isSubmitting: boolean): void {
    this.isSubmitting = isSubmitting;
  }
}
