import { TablePaginationConfig } from 'antd';

export const appConsts = {
  userManagement: {
    defaultAdminUserName: 'admin',
  },
  localization: {
    defaultLocalizationSourceName: 'Gam',
  },
  authorization: {
    encrptedAuthTokenName: 'enc_auth_token',
  },
  appBaseUrl: process.env.REACT_APP_APP_BASE_URL,
  remoteServiceBaseUrl: process.env.REACT_APP_REMOTE_SERVICE_BASE_URL,
  publicFolderUrl: process.env.PUBLIC_URL,
};

export const msalConfig = {
  auth: {
    providerName: process.env.REACT_APP_AUTH_PROVIDER_NAME ?? '',
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID ?? '',
    authority: process.env.REACT_APP_AUTH_AUTHORITY ?? '',
    redirectUrl: process.env.REACT_APP_AUTH_REDIRECT_URL ?? '',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequestConfig = {
  scopes: ['User.Read', 'email', 'offline_access', 'openid', 'profile'],
};

export const TABLE_PROPERTIES = {
  REFETCH_PERIOD_TIME: 1000 * 10, // ten seconds
};

export const DEFAULT_TABLE_PAGINATION_CONFIG: TablePaginationConfig = {
  current: 1,
  defaultPageSize: 20,
  total: 0,
  showSizeChanger: false,
};
