import AbpUserConfigurationService from 'src/domain/service/AbpUserConfigurationService';
import HttpAdapter from 'src/infra/http/HttpAdapter';

export type ConfigurationDTO = any;

export default class AbpUserConfigurationRestService implements AbpUserConfigurationService {
  constructor(readonly httpAdapter: HttpAdapter) {}

  public async getAll(): Promise<ConfigurationDTO> {
    const result = await this.httpAdapter.get('/AbpUserConfiguration/GetAll');
    return result;
  }
}
