import Utils from 'src/utils/utils';
import { useEffect, useState } from 'react';
import * as moment from 'moment';
import 'moment-timezone';
import Group from 'src/components/Group';
import Loading from 'src/components/Loading';
import Title from 'src/components/Title';
import Text from 'src/components/Text';
import * as React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import useStores from 'src/stores/storesConfig';

Utils.setLocalization();

const AbpSessionLoader = ({ children }) => {
  const { sessionStore } = useStores();
  const [sessionInfo, setSessionInfo] = useState<{
    isLoading: boolean;
    errorMessage: string;
  }>({
    errorMessage: '',
    isLoading: true,
  });

  useEffect(() => {
    (async function fetchSession() {
      try {
        const data = await sessionStore.getUserConfiguration();
        Utils.extend(true, abp, data.result);
        abp.clock.provider = Utils.getCurrentClockProvider(data.result.clock.provider);
        moment.locale(abp.localization.currentLanguage.name);

        if (abp.clock.provider.supportsMultipleTimezone) {
          moment.tz.setDefault(abp.timing.timeZoneInfo.iana.timeZoneId);
        }
        setSessionInfo({ isLoading: false, errorMessage: '' });
      } catch (err: unknown) {
        setSessionInfo({
          isLoading: false,
          errorMessage: err instanceof Error ? err?.message : '',
        });
      }
    })();
  }, []);

  if (sessionInfo.isLoading) {
    return (
      <Group className="column i-center">
        <Loading />
        <Title level={5} className="mt-2">
          Loading user session ...
        </Title>
      </Group>
    );
  }

  if (sessionInfo.errorMessage) {
    return (
      <Group style={{ paddingTop: 100 }} className="column j-center i-center">
        <ExclamationCircleOutlined style={{ color: 'red', fontSize: 32 }} />
        <Title className="mt-2" level={4}>
          Something has happened!
        </Title>
        <Text className="text-center w-50 mt-1">{sessionInfo.errorMessage}</Text>
      </Group>
    );
  }

  return children;
};

export default AbpSessionLoader;
