import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import useStores from 'src/stores/storesConfig';

const App = observer((props) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const { sessionStore } = useStores();

  useEffect(() => {
    (async function fetchData() {
      await sessionStore.getCurrentLoginInformations();
      setLoading(false);
    })();
  }, []);

  if (isLoading) {
    return null;
  }
  return props.children;
});

export default App;
