import { makeAutoObservable } from 'mobx';
import RootStore from 'src/stores/rootStore';
import {
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  addEdge,
  applyNodeChanges,
  applyEdgeChanges,
} from 'reactflow';
import {
  StationNode,
  defaultEdgeParams,
  generateEdges,
  generateEdgesForEmptyConfiguration,
  generateNodes,
  getLayoutedElements,
  getMergeFlowConfiguration,
} from 'src/scenes/AddEditRiver/components/MergeSplit/utils';
import { RiverSystemService } from 'src/domain/service/RiverSystemService';
import Utils from 'src/utils/utils';
import { RiverFlowDto } from 'src/domain/service/ScenarioService/dto';
import { GetMergeFlowConfigurationHistoricalOutput } from 'src/domain/service/RiverSystemService/dto';

export enum CurrentStep {
  DONE,
  ERROR,
  FETCHING,
  HISTORICAL_DATA_LOADING,
}

export interface FlowConfiguration {
  flows: RiverFlowDto[];
  stations: StationNode[];
}

export default class MergeSplitStore {
  nodes: Node[] = [];

  pendingNodes: Node[] = [];

  errorDescription = '';

  edges: Edge[] = [];

  step: CurrentStep = CurrentStep.DONE;

  initialSnapshot = '';

  isEditing = false;

  constructor(readonly _: RootStore, readonly riverSystemService: RiverSystemService) {
    makeAutoObservable(this, { _: false });
  }

  public async init(
    riverSystemId: number,
    mergeFlowConfigurationHistoricalOutput?: GetMergeFlowConfigurationHistoricalOutput,
  ): Promise<void> {
    try {
      this.setStep(CurrentStep.FETCHING);
      let configuration;
      if (mergeFlowConfigurationHistoricalOutput) {
        // note: only historical configurations have the stations, still use
        // the riverSystemStore fetch in getLayoutedElements for all other cases
        configuration = getMergeFlowConfiguration(mergeFlowConfigurationHistoricalOutput);
      } else {
        configuration = await this.riverSystemService.getMergeFlowConfiguration(riverSystemId);
      }
      const { nodes, edges } = getLayoutedElements(
        configuration.stations?.length > 0
          ? generateNodes(configuration.stations)
          : generateNodes(this._.riverSystemStore.stationsWithId),
        configuration.flows.length > 0
          ? generateEdges(configuration.flows)
          : generateEdgesForEmptyConfiguration(this._.riverSystemStore.stationsWithId),
      );
      this.setNodes(nodes);
      this.setEdges(edges);
      this.setIsEditing(false);
      this.updateInitialSnapshot();
      this.setStep(CurrentStep.DONE);
    } catch (err) {
      this.errorDescription = Utils.getErrorMessage(err);
      this.setStep(CurrentStep.ERROR);
    }
  }

  public async update(
    stationsWithId: Array<StationNode>,
    stationsWithoutId: Array<StationNode>,
  ): Promise<void> {
    const newNodes = generateNodes(stationsWithId);
    const newEdges = this.edges.filter(
      (e) => !!newNodes.find((n) => n.id === e.target) && !!newNodes.find((n) => n.id === e.source),
    );
    const { nodes, edges } = getLayoutedElements(newNodes, newEdges);
    this.pendingNodes = generateNodes(stationsWithoutId);
    this.setNodes(nodes);
    this.setEdges(edges);
  }

  public onNodesChange(changes: NodeChange[]): void {
    const parsedChanges = changes.reduce((acum, change) => {
      const validChange =
        change.type !== 'remove' ||
        (change.type === 'remove' && this.nodes.find((n) => n.id === change.id)?.data.deletable);
      if (validChange) {
        return acum.concat(change);
      }
      return acum;
    }, [] as NodeChange[]);

    this.setNodes(applyNodeChanges(parsedChanges, this.nodes));
  }

  public onEdgesChange(edges: EdgeChange[]): void {
    // case 1: select edges
    if (edges.every((e) => e.type === 'select')) {
      this.setEdges(applyEdgeChanges(edges, this.edges));
      return;
    }

    // case 2: remove the selected edges
    if (edges.every((e) => e.type === 'remove')) {
      this.setEdges(applyEdgeChanges(edges, this.edges));
      return;
    }

    // case 3: change a edge label
    if (edges.some((e) => e.type === 'reset')) {
      this.setEdges(applyEdgeChanges(edges, this.edges));
      return;
    }

    // case 4: ignore any other edge cases
    this.setEdges(applyEdgeChanges([], this.edges));
  }

  public onConnect(edge: Edge | Connection): void {
    this.setEdges(addEdge({ ...edge, ...defaultEdgeParams }, this.edges));
  }

  public setStep(step: CurrentStep): void {
    this.step = step;
  }

  get stationFlows(): Array<{
    id: number | undefined;
    stationSourceName: string;
    stationTargetName: string;
    sourceId?: number;
    targetId?: number;
    percentage: number;
  }> {
    return this.edges.map((e) => {
      return {
        // ignore generated IDs when sending to the API
        id: e.id.includes('reactflow__edge') ? undefined : parseInt(e.id, 10),
        stationSourceName: this.nodes.find((n) => n.id === e.source)?.data.label,
        sourceId: e.source.includes('local__id__') ? undefined : parseInt(e.source, 10),
        targetId: e.target.includes('local__id__') ? undefined : parseInt(e.target, 10),
        stationTargetName: this.nodes.find((n) => n.id === e.target)?.data.label,
        percentage: e.data.label,
      };
    });
  }

  public updateInitialSnapshot(): void {
    this.initialSnapshot = this.currentSnapshot;
  }

  public reset(): void {
    this.setEdges([]);
    this.setNodes([]);
    this.setStep(CurrentStep.DONE);
    this.setIsEditing(false);
    this.updateInitialSnapshot();
  }

  public setNodes(nodes: Node[]): void {
    this.nodes = nodes;
  }

  public setEdges(edges: Edge[]): void {
    this.edges = edges;
  }

  public setIsEditing(isEditing: boolean): void {
    this.isEditing = isEditing;
  }

  get currentSnapshot(): string {
    return this.stationFlows
      .map((sf) => `${sf.sourceId}${sf.targetId}${sf.percentage}`)
      .sort()
      .join('');
  }

  get connectionsReady(): boolean {
    const nodes = this.nodes.concat(this.pendingNodes);
    if (nodes.length === 0) return false;
    if (nodes.length === 1) return true;
    return nodes.every((n) => !!this.edges.find((e) => e.target === n.id || e.source === n.id));
  }

  get hasContentChanged(): boolean {
    return this.initialSnapshot !== this.currentSnapshot;
  }

  get wrongOutflows(): Array<string> {
    const splitsInfo = this.stationFlows.reduce((acum, edge) => {
      const currentSum = acum[edge.stationSourceName] ?? 0;
      return {
        ...acum,
        [edge.stationSourceName]: currentSum + Number(edge.percentage),
      };
    }, {});
    const stations = Object.keys(splitsInfo).filter((key) => splitsInfo[key] > 100);
    return stations;
  }

  switchEditing(): void {
    this.setIsEditing(!this.isEditing);
  }
}
