import { EntityChange } from 'src/domain/entity/EntityChange';
import { RegionsByCountry } from 'src/domain/entity/RegionsByCountry';
import { River } from 'src/domain/entity/River';
import { RiverSystemService } from 'src/domain/service/RiverSystemService';
import {
  GetMergeFlowConfigurationOutput,
  RiverSystemCheckRiverChangesOutput,
  RiverSystemCreateInput,
  RiverSystemHistoryLogInput,
  RiverSystemHistoryLogOutput,
  RiverSystemUpdateInput,
} from 'src/domain/service/RiverSystemService/dto';
import { toEntityChanges } from 'src/infra/gateway/api/mappers';
import HttpAdapter from 'src/infra/http/HttpAdapter';
import { z } from 'zod';

export default class RiverSystemRestService implements RiverSystemService {
  constructor(readonly httpAdapter: HttpAdapter) {}

  async getCountriesGrouppedByRegion(): Promise<RegionsByCountry> {
    const data = await this.httpAdapter.get<{
      result: Array<{
        countryIso: string;
        regions: Array<string>;
      }>;
    }>('api/services/app/RiverSystem/GetRegionByCountry');

    const regionsByCountry = data.result.reduce(
      (acum, next) => ({
        ...acum,
        [next.countryIso]: next.regions,
      }),
      {},
    );

    return regionsByCountry;
  }

  async getByCountryAndRegion(country: string, region: string): Promise<Array<River>> {
    const data: any = await this.httpAdapter.get('api/services/app/RiverSystem/GetAllFiltered', {
      countryIso: country,
      region,
    });

    return data.result;
  }

  async get(id: number): Promise<River> {
    const data = await this.httpAdapter.get<{
      result: River;
    }>('api/services/app/RiverSystem/Get', { id });

    return data.result;
  }

  async getAll(): Promise<Array<River>> {
    const data = await this.httpAdapter.get<{
      result: { items: Array<River> };
    }>('api/services/app/RiverSystem/GetAll', { MaxResultCount: 100, Sorting: 'name' });

    return data.result.items;
  }

  async getByName(name: string): Promise<River> {
    const data = await this.httpAdapter.get<{
      result: River;
    }>('api/services/app/RiverSystem/GetByName', { name });

    return data.result;
  }

  async create(input: RiverSystemCreateInput): Promise<void> {
    await this.httpAdapter.post('api/services/app/RiverSystem/Create', input);
  }

  async update(input: RiverSystemUpdateInput): Promise<void> {
    await this.httpAdapter.put('api/services/app/RiverSystem/Update', input);
  }

  async getHistoryLog(input: RiverSystemHistoryLogInput): Promise<RiverSystemHistoryLogOutput> {
    const data = await this.httpAdapter.get<z.infer<typeof schema>>(
      'api/services/app/RiverSystem/GetHistoryLog',
      input,
    );

    const schema = z.object({
      result: z.object({
        items: z.array(
          z.object({
            id: z.number(),
            riverSystemName: z.string(),
            username: z.string(),
            creationTime: z.string(),
          }),
        ),
        total: z.number(),
      }),
    });

    schema.parse(data);
    return data.result;
  }

  async getHistoryLogDetail(id: number): Promise<EntityChange[]> {
    const data = await this.httpAdapter.get<z.infer<typeof schema>>(
      'api/services/app/RiverSystem/GetHistoryLogDetail',
      { id },
    );
    const schema = z.object({
      result: z.array(
        z.object({
          id: z.number(),
          entityName: z.string(),
          entityValue: z.string(),
          changeType: z.string(),
          propertyChanges: z.array(
            z.object({
              id: z.number(),
              newValue: z.string(),
              originalValue: z.string(),
              propertyName: z.string(),
            }),
          ),
        }),
      ),
    });

    schema.parse(data);
    const newItems = toEntityChanges(data.result);
    return newItems;
  }

  async checkIfRiverHasChanged(id: number): Promise<RiverSystemCheckRiverChangesOutput> {
    const data = await this.httpAdapter.get<z.infer<typeof schema>>(
      'api/services/app/RiverSystem/CheckIfRiverHasChanged',
      {
        id,
      },
    );
    const schema = z.object({
      result: z.object({
        hasRiverChanged: z.boolean(),
        changeTime: z.string(),
        items: z.array(
          z.object({
            id: z.number(),
            entityName: z.string(),
            entityValue: z.string(),
            changeType: z.string(),
            propertyChanges: z.array(
              z.object({
                id: z.number(),
                newValue: z.string(),
                originalValue: z.string(),
                propertyName: z.string(),
              }),
            ),
          }),
        ),
      }),
    });

    schema.parse(data);
    const newItems = toEntityChanges(data.result.items);
    return {
      hasRiverChanged: data.result.hasRiverChanged,
      changeTime: data.result.changeTime,
      items: newItems,
    };
  }

  async markRiverChangeAsRead(riverSystemId: number): Promise<void> {
    await this.httpAdapter.put('api/services/app/RiverSystem/MarkRiverChangeAsRead', {
      id: riverSystemId,
    });
  }

  async getMergeFlowConfiguration(id: number): Promise<GetMergeFlowConfigurationOutput> {
    const res: any = await this.httpAdapter.get(
      'api/services/app/RiverSystem/GetMergeFlowConfiguration',
      {
        id,
      },
    );
    return res.result;
  }
}
