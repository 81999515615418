import { ROUTES } from 'src/components/Router/router.config';
import { CurrentStep } from 'src/stores/createScenarioStore';

export type ScenarioState =
  | 'Created'
  | 'DatabricksGenerateFileJobStarted'
  | 'DatabricksGenerateFileJobFailed'
  | 'DatabricksGenerateFileJobSuccessful'
  | 'SanityCheckStarted'
  | 'SanityCheckFailed'
  | 'SanityCheckDatabricksFailed'
  | 'SanityCheckSuccessful'
  | 'RunScenarioPending'
  | 'RunScenarioStarted'
  | 'RunScenarioFailed'
  | 'RunScenarioSuccessful'
  | 'RunScenarioCancelled'
  | 'ScenarioResetOrDuplicated'
  | 'InitiatingCancelSanityCheck'
  | 'CancellingSanityCheck';

export const DRAFT_STATES_SCREEN_MAPPING: Omit<
  Record<ScenarioState, (param: number) => void>,
  | 'DatabricksGenerateFileJobStarted'
  | 'RunScenarioPending'
  | 'RunScenarioStarted'
  | 'RunScenarioFailed'
  | 'RunScenarioSuccessful'
  | 'RunScenarioCancelled'
> = {
  CancellingSanityCheck: ROUTES.VALIDATION_MODEL,
  InitiatingCancelSanityCheck: ROUTES.VALIDATION_MODEL,
  Created: ROUTES.TEMPLATE_CONFIGURATION,
  DatabricksGenerateFileJobFailed: ROUTES.TEMPLATE_CONFIGURATION,
  DatabricksGenerateFileJobSuccessful: ROUTES.VALIDATION_MODEL,
  ScenarioResetOrDuplicated: ROUTES.VALIDATION_MODEL,
  SanityCheckStarted: ROUTES.VALIDATION_MODEL,
  SanityCheckFailed: ROUTES.VALIDATION_MODEL,
  SanityCheckDatabricksFailed: ROUTES.VALIDATION_MODEL,
  SanityCheckSuccessful: ROUTES.VALIDATION_MODEL,
};

export const STATUS_MAPPING: Omit<
  Record<ScenarioState, string>,
  | 'DatabricksGenerateFileJobStarted'
  | 'RunScenarioPending'
  | 'RunScenarioStarted'
  | 'RunScenarioFailed'
  | 'RunScenarioSuccessful'
  | 'RunScenarioCancelled'
> = {
  CancellingSanityCheck: 'Cancelling Sanity Check',
  InitiatingCancelSanityCheck: 'Initiating Cancel Sanity Check',
  Created: 'Template Configuration Pending',
  DatabricksGenerateFileJobFailed: 'Template Configuration Databricks Error',
  DatabricksGenerateFileJobSuccessful: 'Sanity Check not started',
  ScenarioResetOrDuplicated: 'Sanity Check not started',
  SanityCheckStarted: 'Sanity Check not started',
  SanityCheckFailed: 'Sanity Check Failed',
  SanityCheckDatabricksFailed: 'Sanity Check Databricks Error',
  SanityCheckSuccessful: 'Sanity Check Successful ',
};

export const getDraftUrlFromState = (scenarioState: ScenarioState): ((param: string) => string) => {
  return DRAFT_STATES_SCREEN_MAPPING[scenarioState] ?? (() => '');
};

const stateMapping = {
  CancellingSanityCheck: CurrentStep.CANCELLING_SANITY_CHECK,
  InitiatingCancelSanityCheck: CurrentStep.INITIATING_CANCEL_SANITY_CHECK,
  Created: CurrentStep.SCENARIO_CREATED,
  DatabricksGenerateFileJobStarted: CurrentStep.SUBMITTING_TEMPLATE,
  DatabricksGenerateFileJobFailed: CurrentStep.TEMPLATE_DATABRICKS_FAIL,
  DatabricksGenerateFileJobSuccessful: CurrentStep.TEMPLATE_CREATED,
  ScenarioResetOrDuplicated: CurrentStep.TEMPLATE_RESET_OR_DUPLICATE,
  SanityCheckStarted: CurrentStep.VALIDATION_MODEL_SUBMITTING,
  SanityCheckFailed: CurrentStep.VALIDATION_MODEL_FAIL,
  SanityCheckDatabricksFailed: CurrentStep.VALIDATION_MODEL_DATABRICKS_FAIL,
  SanityCheckSuccessful: CurrentStep.VALIDATION_MODEL_SUCCESS,
  RunScenarioPending: CurrentStep.RUN_MODEL,
  RunScenarioStarted: CurrentStep.RUN_MODEL,
  RunScenarioFailed: CurrentStep.RUN_MODEL_FINISHED,
  RunScenarioSuccessful: CurrentStep.RUN_MODEL_FINISHED,
  RunScenarioCancelled: CurrentStep.VALIDATION_MODEL_SUCCESS,
};

export const getCurrentStepFromScenarioState = (scenarioState: ScenarioState): CurrentStep => {
  return stateMapping[scenarioState] ?? CurrentStep.VALIDATION_MODEL_FAIL;
};
