import { makeAutoObservable } from 'mobx';

import GetCurrentLoginInformations from 'src/domain/entity/GetCurrentLoginInformations';
import AbpUserConfigurationService from 'src/domain/service/AbpUserConfigurationService';
import SessionService from 'src/domain/service/SessionService';
import { ConfigurationDTO } from 'src/infra/gateway/api/AbpUserConfigurationRestService';

class SessionStore {
  currentLogin?: GetCurrentLoginInformations;

  constructor(
    readonly abpUserConfigurationService: AbpUserConfigurationService,
    readonly sessionService: SessionService,
  ) {
    makeAutoObservable(this);
  }

  async getCurrentLoginInformations(): Promise<void> {
    const result = await this.sessionService.getCurrentLoginInformations();
    this.currentLogin = result;
  }

  async getUserConfiguration(): Promise<ConfigurationDTO> {
    return this.abpUserConfigurationService.getAll();
  }
}

export default SessionStore;
