import Axios from 'axios';
import ParseAxiosError from './axiosErrorParser';

const blobAxiosInstance = Axios.create({
  timeout: 1000 * 60 * 10, // 2 minutes
});

function onRejectedRequest(error) {
  ParseAxiosError(error);
  return Promise.reject(error);
}

function onFulfilledRequest(config) {
  return config;
}

function onFulfilledResponse(response) {
  return response;
}

function onRejectedResponse(error) {
  ParseAxiosError(error);
  return Promise.reject(error);
}

blobAxiosInstance.interceptors.request.use(onFulfilledRequest, onRejectedRequest);
blobAxiosInstance.interceptors.response.use(onFulfilledResponse, onRejectedResponse);

export default blobAxiosInstance;
