import { makeAutoObservable } from 'mobx';
import { RegionsByCountry } from 'src/domain/entity/RegionsByCountry';
import { River } from 'src/domain/entity/River';
import { RiverSystemService } from 'src/domain/service/RiverSystemService';
import { RiverSystemUpdateInput } from 'src/domain/service/RiverSystemService/dto';
import RootStore from 'src/stores/rootStore';

export default class RiverSystemAddEditStore {
  countriesName: Array<string> = [];

  regionsByCountry: RegionsByCountry = {};

  regionsName: Array<string> = [];

  riverSystems: Array<River> = [];

  isLoading = true;

  isSubmitting = false;

  constructor(readonly _: RootStore, readonly riverSystemService: RiverSystemService) {
    makeAutoObservable(this);
  }

  async fetchCountries(): Promise<void> {
    try {
      this.isLoading = true;
      const regionsByCountry = await this.riverSystemService.getCountriesGrouppedByRegion();
      this.setCountries(Object.keys(regionsByCountry));
      this.regionsByCountry = regionsByCountry;
    } finally {
      this.isLoading = false;
    }
  }

  async fetchRegions(country: string): Promise<void> {
    this.setRegions(this.regionsByCountry[country]);
  }

  async fetchRiverSystems(country: string, region: string): Promise<void> {
    try {
      this.isLoading = true;
      const riverSystems = await this.riverSystemService.getByCountryAndRegion(country, region);
      this.setRiverSystems(riverSystems);
    } finally {
      this.isLoading = false;
    }
  }

  public setCountries(countriesName: Array<string>): void {
    this.countriesName = countriesName;
  }

  public setRegions(regionsName: Array<string>): void {
    this.regionsName = regionsName;
  }

  public setRiverSystems(riverSystems: Array<River>): void {
    this.riverSystems = riverSystems;
  }

  public setIsSubmitting(isSubmitting: boolean): void {
    this.isSubmitting = isSubmitting;
  }

  public async addRiverSystem(
    riverSystemName: string,
    countryIso: string,
    region: string,
  ): Promise<void> {
    try {
      const { stationsStructure, generatorsStructure } = this._.riverSystemStore;
      this.setIsSubmitting(true);
      const input = {
        name: riverSystemName,
        countryIso,
        region,
        flows: this._.mergeSplitStore.stationFlows,
        stations: stationsStructure
          .filter((s) => !s.isDeleted)
          .map((station) => ({
            name: station.name,
            isEnabled: station.isEnabled,
            includeStorage: station.includeStorage,
            generators: generatorsStructure[station.name]
              .filter((g) => !g.isDeleted)
              .map((generator) => ({
                name: generator.name,
                isEnabled: generator.isEnabled,
                priority: generator.priority,
              })),
          })),
      };
      await this.riverSystemService.create(input);
      this._.riverSystemStore.updateInitialSnapshot();
      this._.mergeSplitStore.updateInitialSnapshot();
    } finally {
      this.setIsSubmitting(false);
    }
  }

  public async editRiverSystem(riverSystemId: number): Promise<void> {
    try {
      this.setIsSubmitting(true);
      const { generatorsStructure, stationsStructure } = this._.riverSystemStore;
      const input: RiverSystemUpdateInput = {
        id: riverSystemId,
        flows: this._.mergeSplitStore.stationFlows,
        stations: stationsStructure
          .filter((s) => !s.isDeleted)
          .map((s) => {
            return {
              id:
                s.id?.toString().includes('local__id__') || s.id === undefined
                  ? undefined
                  : parseInt(s.id, 10),
              name: s.name,
              isEnabled: s.isEnabled,
              includeStorage: s.includeStorage,
              generators: generatorsStructure[s.name]
                .filter((g) => !g.isDeleted)
                .map((g) => ({
                  id: g.id,
                  name: g.name,
                  isEnabled: g.isEnabled,
                  priority: g.priority,
                })),
            };
          }),
      };
      await this.riverSystemService.update(input);
      this._.riverSystemStore.updateInitialSnapshot();
      this._.mergeSplitStore.updateInitialSnapshot();
    } finally {
      this.setIsSubmitting(false);
    }
  }
}
