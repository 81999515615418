import './index.less';
import React, { CSSProperties, MouseEvent } from 'react';

interface Props {
  className?: string;
  children: any;
  style?: CSSProperties;
  onMouseEnter?: (e: MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (e: MouseEvent<HTMLDivElement>) => void;
}

export default function Group({
  className = '',
  style = {},
  children,
  onMouseEnter,
  onMouseLeave,
}: Props) {
  return (
    <div
      style={style}
      className={`component-group-container ${className}`}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      {children}
    </div>
  );
}
