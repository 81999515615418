import { makeAutoObservable } from 'mobx';
import ScenarioQueueService from 'src/domain/service/ScenarioQueueService';
import ScenarioService from 'src/domain/service/ScenarioService';
import RootStore from 'src/stores/rootStore';
import Utils from 'src/utils/utils';

export interface PendingRun {
  id: number;
  scenarioId: number;
  scenarioName: string;
  scenarioDescription: string;
  version: string;
}

type CurrentStep = 'FETCHING' | 'DONE' | 'REFETCHING' | 'SUBMITTING';

export default class PendingRunsStore {
  _: RootStore;

  currentStep: CurrentStep = 'FETCHING';

  errorDescription = '';

  pendingRuns: Array<PendingRun> = [];

  constructor(
    rootStore: RootStore,
    readonly scenarioService: ScenarioService,
    readonly scenarioQueueService: ScenarioQueueService,
  ) {
    makeAutoObservable(this, { _: false });
    this._ = rootStore;
  }

  async fetchPendingRuns(): Promise<void> {
    this.setCurrentStep('FETCHING');
    await this.getPendingRuns();
  }

  async refetchPendingRuns(): Promise<void> {
    this.setCurrentStep('REFETCHING');
    await this.getPendingRuns();
  }

  private async getPendingRuns(): Promise<void> {
    try {
      const pendings = await this.scenarioQueueService.getAll();
      const pendingRunsFormatted = pendings.map((run) => ({
        id: run.id,
        scenarioId: run.scenarioId,
        scenarioName: run.scenarioName,
        scenarioDescription: run.scenarioDescription,
        version: run.version,
      }));
      this.setPendingRuns(pendingRunsFormatted);
    } catch (err) {
      this.errorDescription = Utils.getErrorMessage(err);
    } finally {
      this.setCurrentStep('DONE');
    }
  }

  async scheduleScenario(scenarioId: number, scheduleStartTime: string): Promise<void> {
    try {
      this.setCurrentStep('SUBMITTING');
      await this.scenarioService.scheduleRunScenario(scenarioId, scheduleStartTime);
    } finally {
      this.setCurrentStep('DONE');
    }
  }

  async changePriority(
    overQueueRecord: PendingRun,
    activeQueueRecord: PendingRun,
    sortedItems: Array<PendingRun>,
  ): Promise<void> {
    const previous = this.pendingRuns;
    try {
      this.setPendingRuns(sortedItems);
      this.setCurrentStep('REFETCHING');
      await this.scenarioQueueService.switchPriorities({
        activeQueueRecord: {
          id: activeQueueRecord.id,
          version: activeQueueRecord.version,
        },
        overQueueRecord: {
          id: overQueueRecord.id,
          version: overQueueRecord.version,
        },
      });
      const pendings = await this.scenarioQueueService.getAll();
      const pendingRunsFormatted = pendings.map((run) => ({
        key: run.scenarioName,
        id: run.id,
        scenarioId: run.scenarioId,
        scenarioName: run.scenarioName,
        scenarioDescription: run.scenarioDescription,
        version: run.version,
      }));
      this.setPendingRuns(pendingRunsFormatted);
    } catch (err) {
      this.setPendingRuns(previous);
      throw err;
    } finally {
      this.setCurrentStep('DONE');
    }
  }

  async cancelPendingRun(scenarioId: number): Promise<void> {
    try {
      this.setCurrentStep('SUBMITTING');
      await this.scenarioQueueService.cancelByScenarioId(scenarioId);
    } finally {
      this.setCurrentStep('DONE');
    }
  }

  public setPendingRuns(pendingRuns: Array<PendingRun>): void {
    this.pendingRuns = pendingRuns;
  }

  public setCurrentStep(currentStep: CurrentStep): void {
    this.currentStep = currentStep;
  }

  get isRefetching(): boolean {
    return this.currentStep === 'REFETCHING';
  }

  get isLoading(): boolean {
    return this.currentStep === 'FETCHING';
  }

  get isSubmitting(): boolean {
    return this.currentStep === 'SUBMITTING';
  }
}
