import { makeAutoObservable } from 'mobx';
import { getCurrentStepFromScenarioState } from 'src/domain/entity/ScenarioState';
import BlobService from 'src/domain/service/BlobService';
import { SanityCheckProgress } from 'src/domain/entity/SanityCheckProgress';
import ScenarioService from 'src/domain/service/ScenarioService';
import { InputTemplateFile } from 'src/domain/entity/InputTemplateFile';
import { WarningLogRecord } from 'src/domain/entity/WarningLogRecord';
import { CurrentStep } from './createScenarioStore';
import RootStore from './rootStore';

type UploadStatus = 'NOT_UPLOADED' | 'UPLOADING' | 'UPLOADED';

const progressInitValue = {
  percentage: null,
  statusDescription: '',
  successfullyCompletedAt: null,
  sharepointInputFileLastModified: null,
};

export default class ValidationModelStore {
  _: RootStore;

  templateFile: InputTemplateFile = { accessUrl: '' };

  plotsHtml = '';

  csvUrl = '';

  warningLogs: WarningLogRecord[] = [];

  progress: SanityCheckProgress = progressInitValue;

  uploadStatus: UploadStatus = 'NOT_UPLOADED';

  isInitiatingCancelSanityCheck = false;

  isInitiatingSanityCheck = false;

  isProcessingCancel = false;

  constructor(
    rootStore: RootStore,
    readonly scenarioService: ScenarioService,
    readonly blobService: BlobService,
  ) {
    makeAutoObservable(this, { _: false });
    this._ = rootStore;
  }

  public async getTemplateFileUrl(scenarioId: number): Promise<void> {
    const data = await this.scenarioService.getInputTemplateFileUrl(scenarioId);
    this.setTemplateFile(data);
  }

  public async init(scenarioId: number, plotsOnly = ''): Promise<void> {
    this.setUploadStatus('NOT_UPLOADED');
    this.setCurrentStep(CurrentStep.VALIDATION_MODEL_LOADING);
    const nextStep = await this._.scenarioDetailsStore.loadScenarioById(scenarioId);
    if (
      nextStep !== CurrentStep.TEMPLATE_CREATED &&
      nextStep !== CurrentStep.TEMPLATE_RESET_OR_DUPLICATE &&
      nextStep !== CurrentStep.VALIDATION_MODEL_SUBMITTING &&
      nextStep !== CurrentStep.VALIDATION_MODEL_DATABRICKS_FAIL &&
      nextStep !== CurrentStep.VALIDATION_MODEL_FAIL &&
      nextStep !== CurrentStep.VALIDATION_MODEL_SUCCESS &&
      nextStep !== CurrentStep.RUN_MODEL &&
      nextStep !== CurrentStep.RUN_MODEL_FINISHED &&
      nextStep !== CurrentStep.INITIATING_CANCEL_SANITY_CHECK &&
      nextStep !== CurrentStep.CANCELLING_SANITY_CHECK
    ) {
      this._.scenarioDetailsStore.clearScenario();
      throw new Error('This is scenario is not allowed to upload a new file.');
    }

    if (plotsOnly !== 'plotsOnly') {
      await this.getTemplateFileUrl(scenarioId);
    }

    if (nextStep >= CurrentStep.VALIDATION_MODEL_FETCHING_RESULTS) {
      await this.fetchFileResults(scenarioId, nextStep);
    }

    if (
      plotsOnly !== 'plotsOnly' &&
      nextStep !== CurrentStep.INITIATING_CANCEL_SANITY_CHECK &&
      nextStep !== CurrentStep.CANCELLING_SANITY_CHECK
    ) {
      await this.updateProgress(scenarioId);
    }
    if (nextStep === CurrentStep.INITIATING_CANCEL_SANITY_CHECK) {
      this.setIsInitiatingCancelSanityCheck(true);
    }
    this.setCurrentStep(nextStep);
  }

  setCurrentStep = (step: CurrentStep): void => {
    this._.createScenarioStore.setCurrentStep(step);
  };

  setIsProcessingCancel = (isProcessingCancel: boolean): void => {
    this.isProcessingCancel = isProcessingCancel;
  };

  setIsInitiatingCancelSanityCheck = (isInitiatingCancelSanityCheck: boolean): void => {
    this.isInitiatingCancelSanityCheck = isInitiatingCancelSanityCheck;
  };

  setIsInitiatingSanityCheck = (isInitiatingSanityCheck: boolean): void => {
    this.isInitiatingSanityCheck = isInitiatingSanityCheck;
  };

  public async runSanityCheck(scenarioId: number): Promise<void> {
    try {
      this.setCurrentStep(CurrentStep.VALIDATION_MODEL_LOADING);
      this.setProgress(progressInitValue);
      await this.scenarioService.runSanityCheck(scenarioId);
    } catch (err) {
      this.setCurrentStep(CurrentStep.VALIDATION_MODEL);
      throw err;
    }
  }

  public async getSharepointInputFileLastModifiedDate(
    scenarioId: number,
  ): Promise<string | undefined> {
    try {
      const output = await this.scenarioService.getSharepointInputFileLastModifiedDate(scenarioId);
      return output.lastModifiedDate ?? undefined;
    } catch (err) {
      return undefined;
    }
  }

  public async cancelSanityCheck(scenarioId: number): Promise<void> {
    this.setIsProcessingCancel(true);
    this.scenarioService.cancelSanityCheck(scenarioId);
  }

  public async setCancelSanityCheck(scenarioId: number): Promise<void> {
    this.scenarioService.setCancelSanityCheck(scenarioId);
  }

  public async setInitiatingCancelSanityCheck(scenarioId: number): Promise<void> {
    this.scenarioService.setInitiatingCancelSanityCheck(scenarioId);
  }

  private async updateProgress(scenarioId: number): Promise<void> {
    const progress = await this.scenarioService.getSanityCheckProgress(scenarioId);
    this.setProgress(progress);
  }

  public async checkValidationModelStatus(scenarioId: number): Promise<boolean> {
    const data = await this.scenarioService.get(scenarioId);
    this._.scenarioDetailsStore.setScenario(data);
    const oldStep = this._.createScenarioStore.currentStep;
    let currentStep = getCurrentStepFromScenarioState(data.state);
    if (
      this.isInitiatingCancelSanityCheck &&
      currentStep !== CurrentStep.INITIATING_CANCEL_SANITY_CHECK &&
      currentStep !== CurrentStep.CANCELLING_SANITY_CHECK
    ) {
      // adding a skip here to avoid the cancel sanity check button temporarily disappearing
      // in the brief period where the scenario state may have a value representing done set
      // after the cancel has been initiated by a poll that was in process
      currentStep = oldStep;
    }
    const isDone =
      currentStep === CurrentStep.VALIDATION_MODEL_SUCCESS ||
      currentStep === CurrentStep.VALIDATION_MODEL_FAIL ||
      currentStep === CurrentStep.VALIDATION_MODEL_DATABRICKS_FAIL;
    await this.updateProgress(data.id);
    this.setCurrentStep(currentStep);
    return isDone;
  }

  setPlotsHtml(plotsHtml: string): void {
    this.plotsHtml = plotsHtml;
  }

  setCsvUrl(csvUrl: string): void {
    this.csvUrl = csvUrl;
  }

  setWarnings(warningLogs: WarningLogRecord[]): void {
    this.warningLogs = warningLogs;
  }

  public async fetchFileResults(scenarioId: number, currentStep?: CurrentStep): Promise<void> {
    const files = await this.scenarioService.getFiles(scenarioId);
    this.setPlotsHtml(files.plots ? await (await fetch(files.plots)).text() : '');
    this.setCsvUrl(files.csvUrl);
    const step = currentStep ?? this._.createScenarioStore.currentStep;
    if (step === CurrentStep.VALIDATION_MODEL_SUCCESS) {
      const warningLogs = await this.scenarioService.getWarningLogContent(scenarioId);
      this.setWarnings(warningLogs);
    }
  }

  get isUploading(): boolean {
    return this.uploadStatus === 'UPLOADING';
  }

  get isUploadReady(): boolean {
    return this.uploadStatus === 'UPLOADED';
  }

  public setTemplateFile(templateFile: InputTemplateFile): void {
    this.templateFile = templateFile;
  }

  public setUploadStatus(uploadStatus: UploadStatus): void {
    this.uploadStatus = uploadStatus;
  }

  public setProgress(progress: SanityCheckProgress): void {
    this.progress = progress;
  }
}
