import AuthenticationStore from 'src/stores/authenticationStore';
import SessionStore from 'src/stores/sessionStore';
import AbpUserConfigurationServiceImpl from 'src/infra/gateway/api/AbpUserConfigurationRestService';
import AxiosAdapter from 'src/infra/http/AxiosAdapter';
import axiosInstance from 'src/infra/http/axiosInstance';
import blobAxiosInstance from 'src/infra/http/blobAxiosInstance';
import SessionRestService from 'src/infra/gateway/api/SessionRestService';
import TokenAuthRestService from 'src/infra/gateway/api/TokenAuthRestService';
import RiverSystemRestService from 'src/infra/gateway/api/RiverSystemRestService';
import ScenarioRestService from 'src/infra/gateway/api/ScenarioRestService';
import BlobRestService from 'src/infra/gateway/api/BlobRestService';
import ScenarioQueueRestService from 'src/infra/gateway/api/ScenarioQueueRestService';
import ScenarioMemoryService from 'src/infra/gateway/memory/ScenarioMemoryService';
import RiverSystemMemoryService from 'src/infra/gateway/memory/RiverSystemMemoryService';
import BlobMemoryService from 'src/infra/gateway/memory/BlobMemoryService';
import ScenarioQueueMemoryService from 'src/infra/gateway/memory/ScenarioQueueMemoryService';
import ValidationModelStore from 'src/stores/validationModelStore';
import RunDetailsStore from 'src/stores/runDetailsStore';
import BrowserLocalDatabase from 'src/infra/database/BrowserLocalDatabase';
import BrowserBackgroundWorker from 'src/infra/backgroundWorker/BrowserBackgroundWorker';
import RiverSystemAddEditStore from 'src/stores/riverSystemAddEditStore';
import RiverSystemStore from 'src/stores/riverSystemStore';
import HistoryLogStore from 'src/stores/historyLogStore';
import MergeSplitStore from 'src/stores/mergeSplitStore';
import CreateScenarioStore from './createScenarioStore';
import TemplateConfigurationStore from './templateConfigurationStore';
import ScenarioDetailsStore from './scenarioDetailsStore';
import ModelRunHistoryStore from './modelRunHistoryStore';
import DraftStore from './draftStore';
import ActiveRunsStore from './activeRunsStore';
import PendingRunsStore from './pendingRunsStore';
import ScheduledRunsStore from './scheduledRunsStore';

const blobAdapter = new AxiosAdapter(blobAxiosInstance);
const httpAdapter = new AxiosAdapter(axiosInstance);

const abpUserConfigurationService = new AbpUserConfigurationServiceImpl(httpAdapter);
const sessionService = new SessionRestService(httpAdapter);
const tokenAuthService = new TokenAuthRestService(httpAdapter);

const riverSystemService = new RiverSystemRestService(httpAdapter);
const scenarioService = new ScenarioRestService(httpAdapter);
const blobService = new BlobRestService(blobAdapter);
const scenarioQueueService = new ScenarioQueueRestService(httpAdapter);

class RootStore {
  authenticationStore = new AuthenticationStore(tokenAuthService);

  sessionStore = new SessionStore(abpUserConfigurationService, sessionService);

  riverSystemStore = new RiverSystemStore(riverSystemService);

  riverSystemAddEditStore = new RiverSystemAddEditStore(this, riverSystemService);

  createScenarioStore = new CreateScenarioStore(riverSystemService, scenarioService);

  scenarioDetailsStore = new ScenarioDetailsStore(this, riverSystemService, scenarioService);

  templateConfigurationStore = new TemplateConfigurationStore(
    this,
    scenarioService,
    riverSystemService,
  );

  validationModelStore = new ValidationModelStore(this, scenarioService, blobService);

  runDetailsStore = new RunDetailsStore(this, scenarioService, scenarioQueueService);

  modelRunHistoryStore = new ModelRunHistoryStore(this, scenarioService, riverSystemService);

  draftStore = new DraftStore(this, scenarioService);

  activeRunsStore = new ActiveRunsStore(this, scenarioService);

  pendingRunsStore = new PendingRunsStore(this, scenarioService, scenarioQueueService);

  scheduledRunsStore = new ScheduledRunsStore(this, scenarioService);

  historyLogStore = new HistoryLogStore(this, riverSystemService);

  mergeSplitStore = new MergeSplitStore(this, riverSystemService);
}

export default RootStore;
