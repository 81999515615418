import * as React from 'react';

import { Redirect, Route } from 'react-router-dom';
import { ROUTES } from './router.config';

declare let abp: any;

const ProtectedRoute = ({ path, component: Component, permission, render, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!abp.session.userId) {
          return (
            <Redirect
              to={{
                pathname: ROUTES.BASE_USER_ROUTE,
                state: { from: props.location },
              }}
            />
          );
        }

        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default ProtectedRoute;
