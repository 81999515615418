/* eslint-disable import/prefer-default-export */
type Generator<T> = T & { priority: number; isDeleted?: boolean };

interface Res<T> {
  data: Array<Generator<T>>;
  currentPriority: number;
}

export function updatePriorities<T>(items: Array<Generator<T>>): Array<Generator<T>> {
  const prioritizedItems: Res<T> = items.reduce(
    (acum: Res<T>, next) => {
      if (!next.isDeleted) {
        return {
          data: acum.data.concat({ ...next, priority: acum.currentPriority }),
          currentPriority: acum.currentPriority + 1,
        };
      }
      return { data: acum.data.concat(next), currentPriority: acum.currentPriority };
    },
    { data: [], currentPriority: 1 },
  );

  return prioritizedItems.data;
}
