import { AxiosInstance, AxiosResponse } from 'axios';
import HttpAdapter from 'src/infra/http/HttpAdapter';

export default class AxiosAdapter implements HttpAdapter {
  constructor(readonly axiosInstance: AxiosInstance) {}

  async get<T>(url: string, params: Record<string, any>): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.get(url, {
      params,
    });
    return response.data;
  }

  async post<T>(url: string, body: Record<string, any>, config?: Record<string, any>): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.post(url, body, config);
    return response.data;
  }

  async put<T>(
    url: string,
    body: Record<string, any>,
    headers?: Record<string, any> | undefined,
  ): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.put(url, body, headers);
    return response.data;
  }

  async delete<T>(
    url: string,
    body: Record<string, any>,
    headers?: Record<string, any> | undefined,
  ): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.delete(url, {
      params: body,
      ...headers,
    });
    return response.data;
  }
}
