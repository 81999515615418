import { makeAutoObservable } from 'mobx';
import { RunModelProgress } from 'src/domain/entity/ActiveRunProgress';
import ScenarioService from 'src/domain/service/ScenarioService';
import RootStore from 'src/stores/rootStore';
import Utils from 'src/utils/utils';

interface ActiveRun {
  startTime: string | null;
  id: number;
  runId: number | null;
  name: string;
  description: string;
  modelProgress: RunModelProgress;
}

type CurrentStep = 'FETCHING' | 'DONE' | 'REFETCHING' | 'CANCELING';

export default class ActiveRunsStore {
  _: RootStore;

  currentStep: CurrentStep = 'FETCHING';

  errorDescription = '';

  activeRuns: Array<ActiveRun> = [];

  constructor(rootStore: RootStore, readonly scenarioService: ScenarioService) {
    makeAutoObservable(this, { _: false });
    this._ = rootStore;
  }

  async cancelRun(scenarioId: number): Promise<void> {
    try {
      this.setCurrentStep('CANCELING');
      await this.scenarioService.cancelRun(scenarioId);
    } finally {
      this.setCurrentStep('DONE');
    }
  }

  async fetchActiveRuns(): Promise<void> {
    this.setCurrentStep('FETCHING');
    await this.getActiveRuns();
  }

  async refetchActiveRuns(): Promise<void> {
    this.setCurrentStep('REFETCHING');
    await this.getActiveRuns();
  }

  private async getActiveRuns(): Promise<void> {
    try {
      const activeRuns = await this.scenarioService.getByScenarioState({
        states: 'RunScenarioStarted',
      });
      const scenarioIds = activeRuns.map((s) => s.id);
      const activeProgress = await this.scenarioService.getProgressFromActiveRuns(scenarioIds);
      const mappedActiveRuns = activeRuns.map((run, index) => ({
        runId: run.databricksScenarioRunId,
        id: run.id,
        startTime: run.runStartTime,
        modelProgress: activeProgress[index],
        name: run.name,
        description: run.description,
      }));
      this.setActiveRuns(mappedActiveRuns);
    } catch (err) {
      this.errorDescription = Utils.getErrorMessage(err);
    } finally {
      this.setCurrentStep('DONE');
    }
  }

  public setActiveRuns(activeRuns: Array<ActiveRun>): void {
    this.activeRuns = activeRuns;
  }

  public setCurrentStep(currentStep: CurrentStep): void {
    this.currentStep = currentStep;
  }

  get isRefetching(): boolean {
    return this.currentStep === 'REFETCHING';
  }

  get isLoading(): boolean {
    return this.currentStep === 'FETCHING';
  }

  get isCancelling(): boolean {
    return this.currentStep === 'CANCELING';
  }
}
