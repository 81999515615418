import { makeAutoObservable } from 'mobx';
import { RiverSystemService } from 'src/domain/service/RiverSystemService';
import { StationNode } from 'src/scenes/AddEditRiver/components/MergeSplit/utils';
import { updatePriorities } from 'src/stores/utils';

export interface GeneratorInput {
  id?: number;
  name: string;
  stationId?: string;
  stationName: string;
  isEnabled: boolean;
  priority: number;
  isDeleted?: boolean;
}

export interface StationInput {
  id?: string;
  name: string;
  includeStorage: boolean;
  isEnabled: boolean;
  isDeleted?: boolean;
  positionX?: number;
  positionY?: number;
}

type StoreType = 'TEMPLATE' | 'ADD_RIVER' | 'EDIT_RIVER';

type CurrentStep = 'NOT_READY' | 'LOADING' | 'DONE';

let ID = 1000000;
export const GENERATE_ID = (): string => {
  ID += 1;
  return `local__id__${ID}`;
};

export default class RiverSystemStore {
  storeType: StoreType = 'TEMPLATE';

  currentStep: CurrentStep = 'NOT_READY';

  initialSnapshot = '';

  stationsStructure: Array<StationInput> = [];

  generatorsStructure: Record<string, Array<GeneratorInput>> = {};

  constructor(readonly riverSystemService: RiverSystemService) {
    makeAutoObservable(this);
  }

  public async init(riverSystemId: number, storeType: StoreType = 'ADD_RIVER'): Promise<void> {
    try {
      this.reset();
      this.setStoreType(storeType);
      if (storeType === 'ADD_RIVER') return;
      this.setCurrentStep('LOADING');
      const res = await this.riverSystemService.get(riverSystemId);

      const generators: Record<string, Array<GeneratorInput>> = res.stations.reduce(
        (acum, s) => ({
          ...acum,
          [s.name]: s.generators
            .map((g) => ({
              id: g.id,
              name: g.name,
              stationId: s.id.toString(),
              stationName: s.name,
              isEnabled: g.isEnabled,
              priority: g.priority,
            }))
            .sort((a, b) => a.priority - b.priority),
        }),
        {},
      );
      const sortedStations = res.stations.map((s) => ({
        id: s.id.toString(),
        name: s.name,
        includeStorage: s.includeStorage,
        isEnabled: s.isEnabled,
      }));

      this.setGenerators(generators);
      this.setStations(sortedStations);
      this.updateInitialSnapshot();
    } finally {
      this.setCurrentStep('DONE');
    }
  }

  public reset(): void {
    this.setStations([]);
    this.setGenerators({});
    this.setCurrentStep('NOT_READY');
    this.updateInitialSnapshot();
    this.setStoreType('ADD_RIVER');
  }

  public generateId(stationName: string, positionX: number, positionY: number): void {
    this.stationsStructure = this.stationsStructure.map((s) => {
      return s.name === stationName ? { ...s, id: GENERATE_ID(), positionX, positionY } : s;
    });
  }

  public addStation(station: string, includeStorage: boolean): void {
    const stations = this.stationsStructure.concat({
      name: station,
      includeStorage,
      isEnabled: true,
    });
    this.stationsStructure = stations;
    this.generatorsStructure[station] = [];
  }

  public addGenerator(stationName: string, generatorName: string): void {
    const stationId = this.stationsStructure.find((s) => s.name === stationName)?.id;
    const generators = this.generatorsStructure[stationName].concat({
      name: generatorName,
      stationId,
      stationName,
      isEnabled: true,
      priority: -1,
    });
    this.generatorsStructure[stationName] = updatePriorities(generators);
  }

  public updateGeneratorName(stationName: string, generatorName: string, newName: string): void {
    this.generatorsStructure[stationName] = this.generatorsStructure[stationName].map((g) => {
      return g.name === generatorName ? { ...g, name: newName } : g;
    });
  }

  public updateStationName(stationName: string, newName: string): void {
    this.stationsStructure = this.stationsStructure.map((s) => {
      return s.name === stationName ? { ...s, name: newName } : s;
    });
    const { [stationName]: toSwitch, ...rest } = this.generatorsStructure;
    this.generatorsStructure = { ...rest, [newName]: toSwitch };
  }

  public setGeneratorsByStation(station: string, generators: Array<string>): void {
    const newGenerators = generators.map((gen) => {
      return this.generatorsStructure[station].find((g2) => g2.name === gen) as GeneratorInput;
    });
    this.generatorsStructure[station] = updatePriorities(newGenerators);
  }

  public changeIsEnabled(stationName: string, generatorName = ''): void {
    if (generatorName) {
      this.generatorsStructure[stationName] = this.generatorsStructure[stationName].map((g) => {
        return g.name === generatorName ? { ...g, isEnabled: !g.isEnabled } : g;
      });
      return;
    }
    this.stationsStructure = this.stationsStructure.map((s) => {
      return s.name === stationName ? { ...s, isEnabled: !s.isEnabled } : s;
    });
  }

  public changeIncludeStorage(station: string, includeStorage: boolean): void {
    this.stationsStructure = this.stationsStructure.map((s) => {
      return s.name === station ? { ...s, includeStorage } : s;
    });
  }

  public getIsEnabled(stationName: string, generatorName = ''): boolean {
    if (generatorName) {
      const generator = this.generatorsStructure[stationName].find((g) => g.name === generatorName);
      return !!generator?.isEnabled;
    }
    const station = this.stationsStructure.find((s) => s.name === stationName);
    return !!station?.isEnabled;
  }

  public deleteStation(station: string): void {
    const newStations: StationInput[] = this.stationsStructure.map((s) => {
      return s.name !== station ? s : { ...s, isDeleted: true };
    });
    const stationsPrioritized = newStations;
    this.setStations(stationsPrioritized);
    this.generatorsStructure = Object.keys(this.generatorsStructure)
      .filter((stationName) => stationName !== station)
      .reduce((acum, stationName) => {
        return { ...acum, [stationName]: this.generatorsStructure[stationName] };
      }, {});
  }

  public deleteGenerator(station: string, generator: string): void {
    const newGenerators: GeneratorInput[] = this.generatorsStructure[station].map((g) => {
      return g.name !== generator ? g : { ...g, isDeleted: true };
    });
    this.generatorsStructure[station] = updatePriorities(newGenerators);
  }

  public setStations(stations: Array<StationInput>): void {
    this.stationsStructure = stations;
  }

  public setGenerators(generators: Record<string, Array<GeneratorInput>>): void {
    this.generatorsStructure = generators;
  }

  private setCurrentStep(currentStep: CurrentStep): void {
    this.currentStep = currentStep;
  }

  private setStoreType(storeType: StoreType): void {
    this.storeType = storeType;
  }

  get isTemplateLoaded(): boolean {
    return this.currentStep === 'DONE';
  }

  get isDeleteEnabled(): boolean {
    return this.storeType !== 'TEMPLATE';
  }

  get generators(): Record<string, Array<string>> {
    return Object.keys(this.generatorsStructure).reduce(
      (acum, key) => ({
        ...acum,
        [key]: this.generatorsStructure[key].filter((g) => !g.isDeleted).map((g) => g.name),
      }),
      {},
    );
  }

  get currentSnapshot(): string {
    return this.stations.reduce((acum, station) => {
      const stationString = JSON.stringify(station);
      const generatorString = JSON.stringify(this.generatorsStructure[station.name]);
      return `${acum}${stationString}:${generatorString}.`;
    }, '');
  }

  public updateInitialSnapshot(): void {
    this.initialSnapshot = this.currentSnapshot;
  }

  get stations(): Array<StationInput> {
    return this.stationsStructure.filter((s) => !s.isDeleted);
  }

  get isContentReady(): boolean {
    return (
      this.stations.length >= 1 && this.stations.every((s) => this.generators[s.name].length >= 1)
    );
  }

  get isContentReadyForTemplateSubmission(): boolean {
    return (
      this.stations.length >= 1 &&
      this.stations.every(
        (s) => this.generatorsStructure[s.name].filter((g) => g.isEnabled).length >= 1,
      )
    );
  }

  get hasContentChanged(): boolean {
    return this.initialSnapshot !== this.currentSnapshot;
  }

  get stationsWithoutId(): Array<StationNode> {
    return this.stations.filter((s) => s.id === undefined).map((s) => ({ ...s, id: 'empty' }));
  }

  get stationsWithId(): Array<StationNode> {
    return this.stations
      .filter((s) => s.id !== undefined && s.isEnabled)
      .map((s) => ({ ...s, id: s.id as string }));
  }
}
