import axios from 'axios';
import qs from 'qs';
import { appConsts } from 'src/utils/consts';
import ParseAxiosError from './axiosErrorParser';

declare let abp: any;

const axiosInstance = axios.create({
  baseURL: appConsts.remoteServiceBaseUrl,
  timeout: 1000 * 60 * 10, // 2 minutes
  paramsSerializer(params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
});

function onFulfilledRequest(config) {
  const extraConfig = {
    headers: {
      common: {
        Authorization: '',
        '.AspNetCore.Culture': '',
        'Abp.TenantId': '',
      },
    },
  };

  if (abp.auth.getToken()) {
    extraConfig.headers.common.Authorization = `Bearer ${abp.auth.getToken()}`;
  }
  extraConfig.headers.common['.AspNetCore.Culture'] = abp.utils.getCookieValue(
    'Abp.Localization.CultureName',
  );
  extraConfig.headers.common['Abp.TenantId'] = abp.multiTenancy.getTenantIdCookie();

  return {
    ...config,
    ...extraConfig,
  };
}

function onRejectedRequest(error) {
  ParseAxiosError(error);
  return Promise.reject(error);
}

function onFulfilledResponse(response) {
  return response;
}

function onRejectedResponse(error) {
  ParseAxiosError(error);
  return Promise.reject(error);
}

axiosInstance.interceptors.request.use(onFulfilledRequest, onRejectedRequest);
axiosInstance.interceptors.response.use(onFulfilledResponse, onRejectedResponse);

export default axiosInstance;
