import { makeAutoObservable } from 'mobx';
import { EntityChange } from 'src/domain/entity/EntityChange';
import { EntityChangeSet } from 'src/domain/entity/EntityChangeSet';
import { RiverSystemService } from 'src/domain/service/RiverSystemService';
import RootStore from 'src/stores/rootStore';
import Utils from 'src/utils/utils';

type CurrentStep = 'FETCHING' | 'DONE' | 'REFETCHING' | 'CANCELING' | 'FETCHING_DETAILS';

export default class HistoryLogStore {
  currentStep: CurrentStep = 'FETCHING';

  errorDescription = '';

  logRecords: EntityChangeSet[] = [];

  total = -1;

  constructor(readonly _: RootStore, readonly riverSystemService: RiverSystemService) {
    makeAutoObservable(this, { _: false });
  }

  async fetchHistoryLog(pageSize: number, page: number): Promise<void> {
    this.setCurrentStep('FETCHING');
    try {
      await this.getHistoryLog(pageSize, page);
    } catch (err) {
      this.errorDescription = Utils.getErrorMessage(err);
    }
  }

  async refetchHistoryLog(pageSize: number, page: number): Promise<void> {
    this.setCurrentStep('REFETCHING');
    await this.getHistoryLog(pageSize, page);
  }

  public async getHistoryLog(pageSize: number, page: number): Promise<void> {
    try {
      const { items, total } = await this.riverSystemService.getHistoryLog({ pageSize, page });
      const itemsMapped = items.map((l) => ({
        id: l.id,
        username: l.username,
        riverSystemName: l.riverSystemName,
        creationTime: l.creationTime,
      }));
      this.setTotal(total);
      this.setLogRecords(itemsMapped);
    } finally {
      this.setCurrentStep('DONE');
    }
  }

  public async getHistoryLogDetail(id: number): Promise<EntityChange[]> {
    try {
      this.setCurrentStep('FETCHING_DETAILS');
      const res = await this.riverSystemService.getHistoryLogDetail(id);
      return res;
    } finally {
      this.setCurrentStep('DONE');
    }
  }

  public setLogRecords(logRecords: EntityChangeSet[]): void {
    this.logRecords = logRecords;
  }

  public setTotal(total: number): void {
    this.total = total;
  }

  public setCurrentStep(currentStep: CurrentStep): void {
    this.currentStep = currentStep;
  }

  get isFetchingMoreInfo(): boolean {
    return this.currentStep === 'REFETCHING' || this.currentStep === 'FETCHING_DETAILS';
  }

  get isLoading(): boolean {
    return this.currentStep === 'FETCHING';
  }
}
