import { AxiosError } from 'axios';
import AuthenticationStore from 'src/stores/authenticationStore';

const ParseAxiosError = (error: AxiosError) => {
  if (
    error?.response?.status === 400 &&
    (error?.response?.data as { error: string })?.error === 'invalid_grant'
  ) {
    AuthenticationStore.logout();
    throw error;
  }

  if (error?.response?.status === 401) {
    AuthenticationStore.logout();
    throw error;
  }
};

export default ParseAxiosError;
