import { makeAutoObservable } from 'mobx';
import { getCurrentStepFromScenarioState } from 'src/domain/entity/ScenarioState';
import ScenarioService from 'src/domain/service/ScenarioService';
import ScenarioQueueService from 'src/domain/service/ScenarioQueueService';
import RootStore from './rootStore';
import { CurrentStep } from './createScenarioStore';

type LoadingType = 'LOADING' | 'DONE' | 'ERROR';

export default class RunDetailsStore {
  _: RootStore;

  creatorEmailAddress = '';

  notificationEmailAddress = '';

  isToSendEmailNotification = false;

  riverSystemName = '';

  scheduleTime = '';

  detailsLoading: LoadingType = 'LOADING';

  isEmailLoading = false;

  constructor(
    rootStore: RootStore,
    readonly scenarioService: ScenarioService,
    readonly scenarioQueueService: ScenarioQueueService,
  ) {
    makeAutoObservable(this, { _: false });
    this._ = rootStore;
  }

  async init(scenarioId: number): Promise<void> {
    try {
      const nextStep = await this._.scenarioDetailsStore.loadScenarioById(scenarioId);
      if (nextStep < CurrentStep.VALIDATION_MODEL_SUCCESS) {
        this._.scenarioDetailsStore.clearScenario();
        throw new Error('This is scenario is not allowed to run a model.');
      }

      const scenarioInfo = this._.scenarioDetailsStore.scenario;
      this.notificationEmailAddress = scenarioInfo.notificationUserEmail;
      this.isToSendEmailNotification = scenarioInfo.isToSentEmailNotification;
      this.creatorEmailAddress = scenarioInfo.creatorUser.emailAddress;
      this.riverSystemName = scenarioInfo.riverSystemName;
      this.scheduleTime = scenarioInfo.scheduleStartTime ?? '';
      this._.createScenarioStore.setCurrentStep(nextStep);
      this.setDetailsLoading('DONE');
    } catch (err) {
      this.setDetailsLoading('ERROR');
      throw err;
    }
  }

  async runModel(scenarioId: number): Promise<void> {
    try {
      this._.createScenarioStore.setCurrentStep(CurrentStep.RUN_MODEL);
      await this.scenarioService.runScenario(scenarioId);
      this.setScheduleTime('');
    } catch (err) {
      this._.createScenarioStore.setCurrentStep(CurrentStep.VALIDATION_MODEL_SUCCESS);
      throw err;
    }
  }

  async updateEmailNotification(
    scenarioId: number,
    email: string,
    isNotificationEnabled: boolean,
  ): Promise<void> {
    try {
      this.setIsEmailLoading(true);
      await this.scenarioService.updateEmailNotification(scenarioId, email, isNotificationEnabled);
      this.notificationEmailAddress = email;
    } finally {
      this.setIsEmailLoading(false);
    }
  }

  async scheduleCurrentScenario(scenarioId: number, scheduleStartTime: string): Promise<void> {
    await this.scenarioService.scheduleRunScenario(scenarioId, scheduleStartTime);
    this.setScheduleTime(scheduleStartTime);
  }

  async resetSchedule(scenarioId: number): Promise<void> {
    await this.scenarioService.scheduleRunScenario(scenarioId, null);
    this.setScheduleTime('');
  }

  async editModel(scenarioId: number): Promise<void> {
    await this.scenarioService.reset(scenarioId);
  }

  async resetToRunnableModel(scenarioId: number): Promise<void> {
    await this.scenarioService.resetToRunnable(scenarioId);
  }

  async getOutputUrl(scenarioId: number): Promise<string> {
    const url = await this.scenarioService.getRunModelOutputUrl(scenarioId);
    return url;
  }

  async getInputXlsxFile(scenarioId: number): Promise<string> {
    // to be updated
    const data = await this.scenarioService.getFiles(scenarioId);
    return data.inputXlsxUrl;
  }

  async getWarningLog(scenarioId: number): Promise<string> {
    const data = await this.scenarioService.getFiles(scenarioId);
    return data.csvUrl;
  }

  async deleteRun(scenarioId: number): Promise<void> {
    await this.scenarioService.delete(scenarioId);
  }

  private setDetailsLoading(detailsLoading: LoadingType): void {
    this.detailsLoading = detailsLoading;
  }

  public setScheduleTime(scheduleTime: string): void {
    this.scheduleTime = scheduleTime;
  }

  public setIsEmailLoading(isEmailLoading: boolean): void {
    this.isEmailLoading = isEmailLoading;
  }

  public setNotificationEmailAddress(notificationEmailAddress: string): void {
    this.notificationEmailAddress = notificationEmailAddress;
  }
}
